import {
  GetProductVariationsParams,
  CreateProductVariationParams,
  ProductVariation,
  ProductVariationPopulated,
} from '@/data/product-variation/types'
import { HttpService } from '@/util/http'

export default class {
  constructor(private readonly httpService: HttpService) {}

  async getProductVariations(
    params?: GetProductVariationsParams
  ): Promise<ProductVariation[]> {
    const res = await this.httpService.get<
      GetProductVariationsParams,
      ProductVariation[]
    >('product-variations', params)
    return res.data
  }

  async getProductVariation(
    productVariationId: number
  ): Promise<ProductVariationPopulated> {
    const res = await this.httpService.get<null, ProductVariationPopulated>(
      `product-variations/${productVariationId}`
    )
    return res.data
  }

  async createProductVariation(
    params: CreateProductVariationParams
  ): Promise<ProductVariation> {
    const res = await this.httpService.post<
      CreateProductVariationParams,
      ProductVariation
    >('product-variations', params)
    return res.data
  }

  async updateProductVariation(
    productVariationId: number,
    data: Partial<ProductVariation>
  ): Promise<ProductVariation> {
    const res = await this.httpService.patch<
      Partial<ProductVariation>,
      ProductVariation
    >(`product-variations/${productVariationId}`, data)
    return res.data
  }

  async deleteProductVariation(
    productVariationId: number
  ): Promise<ProductVariation> {
    const res = await this.httpService.delete<ProductVariation>(
      `product-variations/${productVariationId}`
    )
    return res.data
  }
}
