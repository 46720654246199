
import { defineComponent } from 'vue'

import SideMenu from '@/components/SideMenu.vue'

export default defineComponent({
  components: { SideMenu },
  data() {
    return {
      breakpoints: {
        toast: {
          '576px': { width: '100%', left: '0', right: '0' },
        },
      },
    }
  },
  computed: {
    sideMenuVisible: {
      get(): boolean {
        return this.$store.getters.expanded
      },
      set(visible: boolean): void {
        if (visible) {
          this.$store.commit('expandSideMenu')
        } else {
          this.$store.commit('collapseSideMenu')
        }
      },
    },
  },
})
