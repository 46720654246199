
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['update:visible'],
  data() {
    return {
      routes: [
        { text: 'Inventário', path: '/inventories' },
        { text: 'Movimentos', path: '/movements' },
        { text: 'Peixes', path: '/fishes' },
        { text: 'Açudes', path: '/ponds' },
        { text: 'Produtos', path: '/products' },
        { text: 'Kois', path: '/kois' },
      ],
    }
  },
  computed: {
    visibleComputed: {
      get(): boolean {
        return this.visible
      },
      set(visible: boolean) {
        this.$emit('update:visible', visible)
      },
    },
    loggedIn(): boolean {
      return this.$store.getters.loggedIn
    },
    userName(): string {
      if (!this.loggedIn) {
        return ''
      }
      const { name } = this.$store.getters.session.user
      return name
    },
    userCapitalLetter(): string {
      return this.userName.substr(0, 1).toUpperCase()
    },
  },
  methods: {
    async handleSignout() {
      await this.$store.dispatch('signout')
      this.visibleComputed = false
      this.$router.push({ path: '/signin' })
    },
    async handleRouteClick(navigate: () => void) {
      navigate()
      this.visibleComputed = false
    },
  },
})
