import {
  GetProductsParams,
  CreateProductParams,
  Product,
  ProductPopulated,
} from '@/data/product/types'
import { HttpService } from '@/util/http'

export default class {
  constructor(private readonly httpService: HttpService) {}

  async getProducts(params?: GetProductsParams): Promise<Product[]> {
    const res = await this.httpService.get<GetProductsParams, Product[]>(
      'products',
      params
    )
    return res.data
  }

  async getProduct(productId: number): Promise<ProductPopulated> {
    const res = await this.httpService.get<null, ProductPopulated>(
      `products/${productId}`
    )
    return res.data
  }

  async createProduct(params: CreateProductParams): Promise<Product> {
    const res = await this.httpService.post<CreateProductParams, Product>(
      'products',
      params
    )
    return res.data
  }

  async updateProduct(
    productId: number,
    data: Partial<Product>
  ): Promise<Product> {
    const res = await this.httpService.patch<Partial<Product>, Product>(
      `products/${productId}`,
      data
    )
    return res.data
  }

  async deleteProduct(productId: number): Promise<Product> {
    const res = await this.httpService.delete<Product>(`products/${productId}`)
    return res.data
  }
}
