import {
  GetKoisParams,
  CreateKoiParams,
  Koi,
  GetKoisResponse,
} from '@/data/koi/types'
import { HttpService } from '@/util/http'

export default class {
  constructor(private readonly httpService: HttpService) {}

  async getKois(params?: GetKoisParams): Promise<GetKoisResponse> {
    const res = await this.httpService.get<GetKoisParams, GetKoisResponse>(
      'kois',
      params
    )

    return res.data
  }

  async getKoi(koiId: number): Promise<Koi> {
    const res = await this.httpService.get<null, Koi>(`kois/${koiId}`)
    return res.data
  }

  async createKoi(params: CreateKoiParams): Promise<Koi> {
    const res = await this.httpService.post<CreateKoiParams, Koi>(
      'kois',
      params
    )
    return res.data
  }

  async updateKoi(koiId: number, data: Partial<Koi>): Promise<Koi> {
    const res = await this.httpService.patch<Partial<Koi>, Koi>(
      `kois/${koiId}`,
      data
    )
    return res.data
  }

  async deleteKoi(koiId: number): Promise<Koi> {
    const res = await this.httpService.delete<Koi>(`kois/${koiId}`)
    return res.data
  }
}
