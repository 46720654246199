import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OToast = _resolveComponent("OToast")
  const _component_OConfirmDialog = _resolveComponent("OConfirmDialog")
  const _component_SideMenu = _resolveComponent("SideMenu")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_OToast, {
      breakpoints: _ctx.breakpoints.toast,
      "data-test": "toast"
    }, null, 8, ["breakpoints"]),
    _createVNode(_component_OConfirmDialog),
    _createVNode(_component_SideMenu, {
      visible: _ctx.sideMenuVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.sideMenuVisible = $event))
    }, null, 8, ["visible"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, {
          "enter-active-class": route.meta.enterActiveClass,
          "leave-active-class": route.meta.leaveActiveClass
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1032, ["enter-active-class", "leave-active-class"])
      ]),
      _: 1
    })
  ], 64))
}