import { RouteRecordRaw } from 'vue-router'
import { checkAuth } from '@/router/auth'

export default [
  {
    name: 'kois',
    path: '/kois',
    beforeEnter: checkAuth,
    component: () => import('@/views/koi/KoiList.vue'),
  },
  {
    name: 'koiEdit',
    path: '/kois/:koiId/edit',
    beforeEnter: checkAuth,
    component: () => import('@/views/koi/KoiForm.vue'),
    props: (route) => ({
      koiId: Number(route.params.koiId),
    }),
  },
  {
    name: 'koiCreate',
    path: '/kois/create',
    beforeEnter: checkAuth,
    component: () => import('@/views/koi/KoiForm.vue'),
  },
] as Array<RouteRecordRaw>
