import { RouteRecordRaw } from 'vue-router'
import { checkAuth } from '@/router/auth'

export default [
  {
    name: 'products',
    path: '/products',
    beforeEnter: checkAuth,
    component: () => import('@/views/product/ProductList.vue'),
  },
  {
    name: 'productEdit',
    path: '/products/:productId/edit',
    beforeEnter: checkAuth,
    component: () => import('@/views/product/ProductForm.vue'),
    props: (route) => ({
      productId: Number(route.params.productId),
    }),
  },
  {
    name: 'productCreate',
    path: '/products/create',
    beforeEnter: checkAuth,
    component: () => import('@/views/product/ProductForm.vue'),
  },
  {
    name: 'productVariations',
    path: '/products/:productId/variations',
    beforeEnter: checkAuth,
    component: () => import('@/views/product/ProductVariationList.vue'),
    props: (route) => ({
      productId: Number(route.params.productId),
    }),
  },
  {
    name: 'productVariationsEdit',
    path: '/products/:productId/variations/:productVariationId/edit',
    beforeEnter: checkAuth,
    component: () => import('@/views/product/ProductVariationForm.vue'),
    props: (route) => ({
      productId: Number(route.params.productId),
      productVariationId: Number(route.params.productVariationId),
    }),
  },
  {
    name: 'productVariationsCreate',
    path: '/products/:productId/variations/create',
    beforeEnter: checkAuth,
    component: () => import('@/views/product/ProductVariationForm.vue'),
    props: (route) => ({
      productId: Number(route.params.productId),
    }),
  },
] as Array<RouteRecordRaw>
