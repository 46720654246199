import {
  createRouter,
  createWebHashHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
  RouterScrollBehavior,
} from 'vue-router'

import fishRoutes from '@/router/fish'
import pondRoutes from '@/router/pond'
import inventoryRoutes from '@/router/inventory'
import movementRoutes from '@/router/movement'
import transferRoutes from '@/router/transfer'
import productRoutes from '@/router/product'
import koiRoutes from '@/router/koi'

import { checkAuth } from '@/router/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/signin',
    component: () => import('@/views/signin/SigninForm.vue'),
  },
  {
    path: '/',
    beforeEnter: checkAuth,
    redirect: '/inventories',
  },
  ...fishRoutes,
  ...pondRoutes,
  ...inventoryRoutes,
  ...movementRoutes,
  ...transferRoutes,
  ...productRoutes,
  ...koiRoutes,
]

const scrollBehavior: RouterScrollBehavior = () => {
  return { top: 0 }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior,
})

router.afterEach((to: RouteLocationNormalized) => {
  handleTransitions(to)
})

function handleTransitions(to: RouteLocationNormalized) {
  const fadeIn = () => {
    to.meta.enterActiveClass = 'animate__animated animate__fadeIn'
  }
  fadeIn()
}

export default router
